<template>
  <div class="pageContol listWrap templateList faceStudentstatistics">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据中心</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">签到信息查询</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 10px">
            <div
              title="学员搜索"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span
                class="itemLabel"
                style="
                  min-width: 5rem;
                  text-align: right;
                  font-size: 14px;
                  padding: 0 0.5rem;
                "
                >学员搜索:</span
              >
              <el-select
                v-model="userId"
                placeholder="请选择"
                no-data-text="没有数据"
                remote
                size="small"
                clearable
                :remote-method="superUserSelect"
                @visible-change="clearUserSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span style="color: #8492a6;float: left;font-size: 13px;width: 100px;"
                      >学员姓名</span
                    >
                    <span style="color: #8492a6;float: left;font-size: 13px;margin-left: 50px;width: 140px;">身份证号码</span>
                    <span style=" color: #8492a6; float: left;font-size: 13px;margin-left: 50px;width: 100px;"
                      >学员电话</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaUserName"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员姓名"
                      clearable
                      style="width: 100px"
                    />
                    <el-input
                      v-model="seaUserIdcard"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="身份证号码"
                      clearable
                      style="margin-left: 50px; width: 140px"
                    />
                    <el-input
                      v-model="seaUserMobile"
                      v-on:input="superUserSelect"
                      type="text"
                      size="small"
                      placeholder="学员电话"
                      clearable
                      style="margin-left: 50px; width: 100px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="(item, index) in userList"
                  :key="index"
                  :label="item.userName"
                  :value="item.userId"
                >
                  <span style="width: 100px; font-size: 13px">{{
                    item.userName
                  }}</span>
                  <span
                    style="width: 140px; margin-left: 50px; font-size: 13px"
                    >{{ item.idcard }}</span
                  >
                  <span
                    style="width: 100px; margin-left: 50px; font-size: 13px"
                    >{{ item.mobile }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div
              title="班级搜索"
              style="display: flex; align-items: center"
              class="searchboxItem ci-full"
            >
              <span
                class="itemLabel" style=" min-width: 5rem;text-align: right;font-size: 14px; padding: 0 0.5rem;"
                >班级搜索:</span
              >
              <el-select
                v-model="projectId"
                placeholder="请选择"
                remote
                size="small"
                clearable
                :remote-method="superProjectSelect"
                @visible-change="clearProjectSearchModel"
              >
                <template>
                  <div class="select-header">
                    <span
                      style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        width: 150px;
                      "
                      >班级编号</span
                    >
                    <span style="
                        color: #8492a6;
                        float: left;
                        font-size: 13px;
                        margin-left: 50px;
                        width: 250px;
                      "
                      >班级名称</span
                    >
                  </div>
                  <div class="select-header">
                    <el-input
                      v-model="seaProjectCode"
                      v-on:input="superProjectSelect"
                      type="text"
                      size="small"
                      placeholder="班级编号"
                      clearable
                      style="width: 150px"
                    />
                    <el-input
                      v-model="seaProjectName"
                      v-on:input="superProjectSelect"
                      type="text"
                      size="small"
                      placeholder="请输入班级名称"
                      clearable
                      style="margin-left: 50px; width: 250px"
                    />
                  </div>
                </template>
                <el-option
                  v-for="(item, index) in projectList"
                  :key="index"
                  :label="item.projectName"
                  :value="item.projectId"
                >
                  <span style="width: 150px; font-size: 13px">{{
                    item.projectCode
                  }}</span>
                  <span
                    style="width: 250px; margin-left: 50px; font-size: 13px"
                    >{{ item.projectName }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div title="签到时间" class="searchboxItem">
              <span class="itemLabel">签到时间:</span>
              <el-date-picker
                  clearable
                  size="small"
                  v-model="OperationTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
<!--            <div title="课程名称" class="searchboxItem">-->
<!--              <span class="itemLabel">课程名称:</span>-->
<!--              <el-input-->
<!--                v-model="courseName"-->
<!--                type="text"-->
<!--                size="small"-->
<!--                placeholder="请输入课程名称"-->
<!--                clearable-->
<!--              />-->
<!--            </div>-->
            <!-- <div
              title="学员姓名"
              style="display: flex; align-items: center"
              class="searchboxItem  "
            >
              <span class="itemLabel">学员姓名:</span>
              <el-input
                v-model="userName"
                type="text"
                size="small"
                placeholder="请输入学员姓名"
                clearable
              />
            </div>
            <div
              title="身份证号"
              style="display: flex; align-items: center"
              class="searchboxItem  "
            >
              <span class="itemLabel">身份证号:</span>
              <el-input
                v-model="userIdcard"
                type="text"
                size="small"
                placeholder="请输入身份证号"
                clearable
              />
            </div>
            <div title="手机号" class="searchboxItem ">
              <span class="itemLabel">手机号:</span>
              <el-input
                v-model="userPhone"
                type="text"
                size="small"
                placeholder="请输入手机号"
                clearable
              />
            </div> -->
            <div class="df" style="padding-right: 20px" >
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="exportData()"
                >导出</el-button
              >
<!--              <el-button-->
<!--                type="primary"-->
<!--                size="small"-->
<!--                class="bgc-bv"-->
<!--                round-->
<!--                @click="seniorSearch"-->
<!--                >高级搜索</el-button-->
<!--              >-->
            </div>
          </div>
<!--          <div-->
<!--            class="searchbox"-->
<!--            style="margin-bottom: 10px"-->
<!--            v-show="searchStatus"-->
<!--          >-->
<!--            <div title="课件名称" class="searchboxItem">-->
<!--              <span class="itemLabel">课件名称:</span>-->
<!--              <el-input-->
<!--                v-model="kpointName"-->
<!--                type="text"-->
<!--                size="small"-->
<!--                placeholder="请输入课件名称"-->
<!--                clearable-->
<!--              />-->
<!--            </div>-->
<!--            <div title="认证类型" class="searchboxItem">-->
<!--              <span class="itemLabel">认证类型:</span>-->
<!--              <el-select-->
<!--                v-model="faceType"-->
<!--                disabled-->
<!--                clearable-->
<!--                placeholder="请选择"-->
<!--                size="small"-->
<!--              >-->
<!--                <el-option-->
<!--                  v-for="item in LearningStateList"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value"-->
<!--                ></el-option>-->
<!--              </el-select>-->
<!--            </div>-->
<!--            <div title="认证状态" class="searchboxItem">-->
<!--              <span class="itemLabel">认证状态:</span>-->
<!--              <el-select-->
<!--                v-model="faceStates"-->
<!--                clearable-->
<!--                placeholder="请选择"-->
<!--                size="small"-->
<!--              >-->
<!--                <el-option-->
<!--                  v-for="item in faceStatelist"-->
<!--                  :key="item.value"-->
<!--                  :label="item.label"-->
<!--                  :value="item.value"-->
<!--                ></el-option>-->
<!--              </el-select>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="searchbox" v-show="searchStatus">-->
<!--            -->
<!--            <div class="df" style="padding-right: 20px">-->
<!--              <el-button-->
<!--                type="primary"-->
<!--                size="small"-->
<!--                class="bgc-bv"-->
<!--                round-->
<!--                @click="getData()"-->
<!--                >查询</el-button-->
<!--              >-->
<!--              <el-button-->
<!--                type="primary"-->
<!--                size="small"-->
<!--                class="bgc-bv"-->
<!--                round-->
<!--                @click="exportData()"-->
<!--                >导出</el-button-->
<!--              >-->
<!--              <el-button-->
<!--                type="primary"-->
<!--                size="small"-->
<!--                class="bgc-bv"-->
<!--                round-->
<!--                @click="putAwaySearch"-->
<!--                >收起搜索</el-button-->
<!--              >-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                fixed
                :index="indexMethod"
                width="100px"
              />
              <el-table-column
                label="学员姓名"
                align="left"
                show-overflow-tooltip
                prop="userName"
                minWidth="120"
                fixed
              />
              <el-table-column
                label="身份证号"
                align="left"
                show-overflow-tooltip
                prop="idcard"
                minWidth="160"
                fixed
              />
              <el-table-column
                label="手机号"
                align="left"
                show-overflow-tooltip
                prop="mobile"
                minWidth="120"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                minWidth="150"
              />
              <el-table-column
                  label="班级编码"
                  align="left"
                  show-overflow-tooltip
                  prop="projectCode"
                  minWidth="180"
              />

              <el-table-column
                label="签到状态"
                align="center"
                show-overflow-tooltip
                width="120"
              >
                <template slot-scope="scope">{{
                  scope.row.faceState == true ? "成功" : "失败"
                }}</template>
              </el-table-column>
              <el-table-column
                label="签到时间"
                align="left"
                show-overflow-tooltip
                width="180"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.createTime | moment }}</span>
                </template>
              </el-table-column>
<!--              <el-table-column label="签到照片" width="120">-->
<!--                <template v-slot="{ row }">-->
<!--                  <el-image-->
<!--                    :src="row.approveFace"-->
<!--                    style="width: 40px; height: 40px"-->
<!--                    :preview-src-list="[row.approveFace]"-->
<!--                    :fit="fit"-->
<!--                  >-->
<!--                  </el-image>-->
<!--                </template>-->
<!--              </el-table-column>-->
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/faceStudentstatistics",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      //高级搜索&收起搜索
      searchStatus: false,
      //学员搜索
      userId: "",
      seaUserName: "",
      seaUserIdcard: "",
      seaUserMobile: "",
      userList: [{}],
      //班级搜索
      projectId: "",
      seaProjectCode: "",
      seaProjectName: "",
      projectList: [{}],
      courseName: "", //课程名
      kpointName: "", //课件名
      faceType: "10", // 认证类型
      LearningStateList: [], // 认证类型
      faceStatelist: [
        {
          value: 1,
          label: "成功",
        },
        {
          value: 0,
          label: "失败",
        },
      ],
      faceStates: "",
      once: true,
      OperationTime: "",
    };
  },
  doNotInit: true,
  created() {
    //每天首次进入的时候弹提示--------- 开始
    let userId = JSON.parse(sessionStorage.getItem("userJson")).userId;
    //获取当前时间
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if (month < 10) {
      month = "0" + month;
    }
    if (day < 10) {
      day = "0" + day;
    }
    let nowDate = year + "-" + month + "-" + day;
    console.log(localStorage.getItem(userId));
    if (!localStorage.getItem(userId)) {
      this.$confirm(
        "根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "提示",
        {
          showClose: false, //是否显示右上角关闭按钮
          showCancelButton: false, //是否显示取消按钮
          closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
          confirmButtonText: "同意并继续",
          confirmButtonClass: "bgc-bv",
        }
      )
        .then(() => {
          let userKey = {};
          userKey["faceStudentstatistics"] = nowDate;
          localStorage.setItem(userId, JSON.stringify(userKey));
        })
        .catch(() => {});
    } else if (
      JSON.parse(localStorage.getItem(userId)).faceStudentstatistics ==
        undefined ||
      JSON.parse(localStorage.getItem(userId)).faceStudentstatistics < nowDate
    ) {
      this.$confirm(
        "根据有关部门业务开展需要，相关数据会涉及个人信息、照片等敏感信息，导出数据仅限于业务范围内使用，不得用于其他用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "提示",
        {
          showClose: false, //是否显示右上角关闭按钮
          showCancelButton: false, //是否显示取消按钮
          closeOnClickModal: false, //是否可通过点击遮罩关闭 MessageBox
          confirmButtonText: "同意并继续",
          confirmButtonClass: "bgc-bv",
        }
      )
        .then(() => {
          let userKey = JSON.parse(localStorage.getItem(userId));
          userKey.faceStudentstatistics = nowDate;
          localStorage.setItem(userId, JSON.stringify(userKey));
        })
        .catch(() => {});
    }
    //每天首次进入的时候弹提示-------- 结束
    this.superUserSelect();
    this.superProjectSelect();
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  mounted() {
    this.getTableHeight();
    this.getLearningStateList();
  },
  watch: {
    projectId: function () {
      this.superUserSelect();
    },
    userId: function () {
      this.superProjectSelect();
    },
  },
  methods: {
    // //高级搜索
    // seniorSearch() {
    //   this.searchStatus = true;
    //   this.getTableHeight();
    // },
    // //收起搜索
    // putAwaySearch() {
    //   this.searchStatus = false;
    //   this.getTableHeight();
    // },
    //获取学员数据
    superUserSelect(e) {
      this.$post(
        "/biz/user/companyUserSelect",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          userName: this.seaUserName,
          idcard: this.seaUserIdcard,
          mobile: this.seaUserMobile,
          projectId: this.projectId,
        },
        3000,
        false
      ).then((res) => {
        if (res.data.list.length === 0) {
          this.userList = [{}];
        } else {
          this.userList = res.data.list;
        }
      });
    },
    //获取班级数据
    superProjectSelect(e) {
      this.$post(
        "/biz/project/superSelectProject",
        {
          pageNum: 1, //每次都只要第一页
          pageSize: 5, //根据次参数控制显示的数据行数，尽量不出现滚动条
          projectName: this.seaProjectName,
          projectCode: this.seaProjectCode,
          userId: this.userId,
        },
        3000,
        false
      ).then((res) => {
        // this.projectList = res.data.list;
        if (res.data.list.length === 0) {
          this.projectList = [{}];
        } else {
          this.projectList = res.data.list;
        }
      });
    },
    clearProjectSearchModel(e) {
      if (e) {
        this.seaProjectName = "";
        this.seaProjectCode = "";
        this.superProjectSelect();
      }
    },
    clearUserSearchModel(e) {
      if (e) {
        this.seaUserName = "";
        this.seaUserIdcard = "";
        this.seaUserMobile = "";
        this.superUserSelect();
      }
    },
    getData(pageNum = 1) {
      console.log(this.OperationTime);
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        // faceState: this.faceStates,
      };
      if (this.userId) {
        params.userId = this.userId;
      }
      // if (this.kpointName) {
      //   params.kpointName = this.kpointName;
      // }
      if (this.projectId) {
        params.projectId = this.projectId;
      }
      if (this.OperationTime) {
        params.startTime = this.OperationTime[0] + " " + "00:00:00";
        params.endTime = this.OperationTime[1] + " " + "23:59:59";
      }
      // if (this.courseName) {
      //   params.courseName = this.courseName;
      // }
      // if (this.faceType) {
      //   params.faceType = this.faceType;
      // }
      this.doFetch({
        url: "/biz/userface/everyDay/login/pageList",
        params,
        pageNum,
      });
    },
    //导出
    exportData() {
      this.$confirm(
        "导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。",
        "导出提示",
        {
          confirmButtonText: "导出",
          confirmButtonClass: "confirmButtonClass",
          cancelButtonText: "取消",
        }
      ).then(() => {
        let params = {
          // faceState: this.faceStates,
        };
        if (this.userId) {
          params.userId = this.userId;
        }
        // if (this.kpointName) {
        //   params.kpointName = this.kpointName;
        // }
        if (this.projectId) {
          params.projectId = this.projectId;
        }
        if (this.OperationTime) {
          params.startTime = this.OperationTime[0] + " " + "00:00:00";
          params.endTime = this.OperationTime[1] + " " + "23:59:59";
        }
        // if (this.courseName) {
        //   params.courseName = this.courseName;
        // }
        // if (this.faceType) {
        //   params.faceType = this.faceType;
        // }
        this.$post("/biz/userface/everyDay/login/export", params).then((res) => {
          if (res.status == "0") {
            let list = res.data;
            if (!this.downloadItems.includes(list.taskId)) {
              this.$store.dispatch("pushDownloadItems", list.taskId);
            } else {
              this.$message.warning(
                "[" + list.fileName + "]已经申请下载,请耐心等待"
              );
            }
          } else {
            this.$message.error(res.message);
          }
        });
      });
    },
    getLearningStateList() {
      const LearningStateList = this.$setDictionary("FACETYPE", "list");
      const list = [];
      for (const key in LearningStateList) {
        list.push({
          value: key,
          label: LearningStateList[key],
        });
      }
      this.LearningStateList = list;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -=
          this.searchStatus == false ? 80 + 0.675 * 16 + 6 : 170 + 0.675 * 16 + 6;
      }
      if (page) {
        tHeight -= 24;
      }
      this.tableHeight = tHeight + 4;
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.faceStudentstatistics {
  .operationControl {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mabtm {
    padding-bottom: 0.675rem;
    border-bottom: 1px solid #f5f5f6;
  }
  .searchboxItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    .el-cascader {
      height: 32px !important;
    }
  }
}
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.imgBox {
  display: flex;
  border: 1px solid #797979;
  .CertificationBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    border-right: 1px solid #797979;
    .certificationTitle {
      height: 3rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #797979;
      background: #f2f2f2;
    }
    .certifictionImg {
      padding: 20px;
      .el-image {
        width: 210px;
        height: 230px;
      }
    }
  }
  .CaptureBox {
    flex: 1;
    width: 500px;
    .CaptureTitle {
      height: 3rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #797979;
      background: #f2f2f2;
    }
    .CaptureList {
      flex: 1;
      overflow-y: auto;
    }
    .certifictionImg {
      padding: 20px;
      .el-image {
        width: 100%;
        height: 230px;
      }
      p {
        padding: 5px 0;
      }
    }
  }
}
</style>
